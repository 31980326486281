/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.CartOutOfStockPopup {
    .Popup {
        &-CloseBtn {
            display: none;
        }

        &-Content {
            padding: 56px;
            min-width: 836px;
            max-width: 836px;

            @include mobile {
                min-width: unset;
                max-width: unset;
                position: fixed;
                height: 444px;
                inset-block-end: 0;
                padding: 40px 20px;
                width: 100%;
                z-index: 1000;
            }
        }

        &-Heading {
            letter-spacing: 0px;
            color: #0F0F0F;
            font-weight: bold;
            text-align: center;
            margin-block-end: 16px;
            font-family: $font-Montserrat;
            font-size: 20px;

            @include mobile {
                font-size: 16px;
                font-weight: 600;
            }
        }

        &-Content::after {
            display: none;
        }

        &-Header {
            @include mobile {
                display: block;
            }
        }
    }

    &_isVisible {
        inset-block-start: 0;
        z-index: 999;
        height: 100vh;
    }

    &-heading {
        letter-spacing: 0px;
        color: #0F0F0F;
        font-size: 14px;
        text-align: center;

        @include mobile {
            font-size: 12px;
            padding-block-end: 24px;
            border-bottom: 1px solid #F1F1F1;
            line-height: 20px;
        }
    }

    &-ProductItemsWrapper {
        margin-block-start: 36px;

        @include mobile {
            margin-block-start: 16px;
        }
    }

    &-TableHead {
        display: grid;
        grid-template-columns: 3fr 1fr 1fr 1fr;
        padding-block-end: 14px;
        margin-block-end: 0;
        border-bottom: 1px solid #CBCBCB;
        letter-spacing: 0px;
        color: #707070;
        font-size: 14px;
        font-weight: bold;
    }

    &-TableBody {
        display: grid;
        padding: 16px 0 0 0;
        border-bottom: 1px solid #707070;
        grid-gap: 16px;
        max-height: 363px;
        overflow-y: auto;

        @include mobile {
            max-height: unset;
            overflow-y: unset;
            border-bottom: unset;
        }
    }

    &-TableRowWrapper {
        display: grid;
        grid-template-columns: 3fr 1fr 1fr 1fr;

        @include mobile {
            grid-template-columns: unset;
        }

        &:not(:last-of-type) {
            border-bottom: 1px solid #707070;

            @include mobile {
                border-bottom: 1px solid #F1F1F1;
            }
        }
    }

    &-OutOfStockActionsButtonWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 36px 0 0 0;
        grid-gap: 24px;

        @include mobile {
            grid-gap: 16px;
            flex-direction: column-reverse;
            width: 100%;
            padding: 20px 20px 0;
            inset-inline-start: 0;
            background: #FFFFFF;
            border-top: 1px solid #F1F1F1;
            margin: 0;
        }
    }

    &-ClearItem.Button {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #E43D2F;
        border-radius: 4px;
        color: #E43D2F;
        text-transform: unset;
        height: 40px;
        padding-inline: 43px;

        @include mobile {
            height: 36px;
            font-size: 12px;
        }

        &:hover {
            height: 40px;
            padding-inline: 43px;
            background: #E43D2F;
            color: #FFFFFF;
            border: 1px solid #E43D2F;

            @include mobile {
                height: 36px;
                font-size: 12px;
            }
        }
    }

    &-MoveToWishlist.Button {
        text-transform: unset;
        height: 40px;
        padding-inline: 30px;

        @include mobile {
            height: 36px;
            font-size: 12px;
        }

        &:hover {
            height: 40px;
            padding-inline: 30px;

            @include mobile {
                height: 36px;
                font-size: 12px;
            }
        }
    }

    &-ProductDetails-Brand {
        letter-spacing: 0px;
        color: var(--primary-base-color);
        font-size: 14px;
        margin-block-end: 10px;

        @include mobile {
            font-size: 12px;
            margin-block-end: 6px;
        }
    }

    &-ProductDetails-Name {
        letter-spacing: 0px;
        color: #000000;
        text-transform: capitalize;
        font-size: 14px;
        font-weight: bold;
        font-family: $font-Montserrat;
        margin-block-end: 10px;
        
        @include mobile {
            font-size: 14px;
            margin-block-end: 6px;
        }
    }

    &-ProductDetails-Sku {
        letter-spacing: 0px;
        color: #707070;
        text-transform: uppercase;
        font-size: 14px;
        margin-block-end: 0;

        @include mobile {
            font-size: 12px;
        }
    }

    &-TableRowData {
        @include mobile {
            display: flex;
            grid-gap: 10px;
        }
    }

    &-TableRowLabel {
        @include mobile {
            letter-spacing: 0px;
            color: #707070;
            text-transform: capitalize;
            font-size: 12px;
            margin-block-end: 0;
        }
    }

    &-Options {
        letter-spacing: 0px;
        color: #0F0F0F;
        font-size: 12px;
        margin-block-start: 10px;

        @include mobile {
            font-size: 12px;
            margin-block-start: 6px;
        }
    }

    &-TableRowSecond {
        @include mobile {
            display: flex;
            grid-gap: 16px;
            padding-block-end: 16px;
            padding-block-start: 6px;
        }
    }

    &-ProductDetailsQty, &-ProductDetailsSubtotal {
        letter-spacing: 0px;
        color: #000000;
        text-transform: capitalize;
        font-size: 14px;
        font-weight: bold;
        margin-block-end: 0;

        @include mobile {
            font-size: 12px;
        }
    }

    &-ProductDetailsStatus {
        letter-spacing: 0px;
        color: var(--primary-base-color);
        font-size: 14px;
        font-weight: bold;
        margin-block-end: 0;

        @include mobile {
            font-size: 12px;
        }
    }

    &-TableRow {
        display: flex;
        align-items: center;

        @include mobile {
            flex-direction: column;
            align-items: unset;
        }
    }

    &-TableRowLabel.Product-Label {
        @include mobile {
            margin-block-end: 6px;
            font-size: 12px;
            letter-spacing: 0px;
            color: #707070;
            text-transform: capitalize;
        }
    }
}
