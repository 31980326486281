/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.CartPage {
    background: #FAFAFA;

    @include mobile {
        margin-block-start: 0;
        padding-block-start: 20px;
        padding-inline: 0;
        padding-block-end: 0;
        margin-block-end: 72px;
    }

    &-Wrapper {
        @include desktop {
            padding-block-end: 57px;
            border-top: 1px solid #F1F1F1;
        }

        @include mobile {
            padding-inline: 24px;
            padding-block-end: 16px;
        }
    }


    &-OrderTotal {
        font-size: 14px;
        font-weight: 500;
        background-color: #fff4f4;
        padding: 1.5rem;
        margin: 2rem 0;
        display: flex;
        gap: 10px;
        color: var(--primary-error-color, #eb0202);
    }

    &-OutOfStockProductsWarning {
        inset-inline-start: unset;
        width: 100%
    }

    &-Freebies {
        display: grid;
        grid-gap: 20px;

        &-Heading {
            @include mobile {
                display: none;
            }

            p {
                font-size: 24px;
                padding: 0 0 16px 0;
                margin: 0;
                letter-spacing: 0px;
                color: #0F0F0F;
                border-bottom: 1px solid #CBCBCB;
                font-weight: 600;

                @include mobile {
                    font-size: 16px;
                    display: none;
                }
            }
        }
    }

    &-FreebiesItemsData {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include mobile {
            flex-direction: column;
            align-items: baseline;
        }
    }

    &-FreebiesItems {
        display: grid;
        grid-template-columns: 110px auto;
        padding: 20px;
        box-shadow: 1px 1px 13px #00000005;
        background: #FFFFFF;

        @include mobile {
            grid-template-columns: 100px auto;
            padding: 10px;
            font-size: 12px;
        }

        &-Addtocart {
            padding: 12px 24px;
            border: 1px solid var(--primary-base-color);
            cursor: pointer;
            text-transform: uppercase;
            font-weight: 600;
            color: var(--primary-base-color);

            &:hover {
                background: var(--primary-base-color);
                color: #FFFFFF;
            }

            @include mobile {
                padding: 10px 12px;
            }
        }

        &-Details {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-inline-start: 16px;

            @include mobile {
                padding-inline-start: 12px;
            }

            a {
                font-weight: normal;
            }

            span {
                letter-spacing: 0px;
                color: var(--primary-base-color);
                font-size: 14px;
                padding-block-end: 10px;

                @include mobile {
                    font-size: 10px;
                    font-weight: bold;
                    padding-block-end: 7px;
                }
            } 

            p {
                letter-spacing: 0px;
                color: #000000;
                opacity: 1;
                font-size: 14px;
                font-family: $font-Montserrat;
                font-weight: 600;

                @include mobile {
                    margin: 0 0 7px 0;
                }
            }
        }

        &-Tag {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            flex-direction: column;
            grid-gap: 20px;

            @include mobile {
                padding-inline-start: 12px;
                align-items: baseline;
                grid-gap: 0;
            }

            p {
                letter-spacing: 0px;
                color: #00741B;
                font-size: 14px;
                font-weight: 600;
                font-family: $font-Montserrat;
                margin-block-end: 0;

                @include mobile {
                    margin-block-end: 6px;
                    font-size: 12px;
                }
            }
        }
    }

    &-Items {
        display: grid;
        grid-gap: 20px;

        @include mobile {
            grid-gap: 16px;
        }
    }

    &-CreditBalanceData {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        background: #FFFFFF;
        box-shadow: 1px 1px 13px #00000005;

        @include mobile {
            padding: 16px;
        }
    }

    &-CreditBalanceDatahead {
        display: flex;
        grid-gap: 10px;
        align-items: center;
    }

    &-YourCreditBalanceData {
        display: flex;
        padding: 20px;
        background: #FFFFFF;
        grid-gap: 10px;
        box-shadow: 1px 1px 13px #00000005;

        @include mobile {
            padding: 16px;
        }
    }

    &-YourCreditBalanceDataPrice {
        display: flex;
        flex-direction: column;
        grid-gap: 10px;
    }

    &-CreditBalanceDataheadlabel {
        letter-spacing: 0px;
        color: #0F0F0F;
        font-size: 14px;
        width: 72%;

        @include mobile {
            font-size: 12px;
        }
    }

    &-CreditBalanceDataPrice {
        letter-spacing: 0px;
        color: var(--primary-base-color);
        font-size: 16px;
        font-weight: bold;
    }

    &-CreditBalanceDataheadlogo, &-YourCreditBalanceDataheadlogo {
        background: #FFF5F4 0% 0% no-repeat padding-box;
        border-radius: 50px;
        width: 28px;
        height: 28px;
    }

    &-YourCreditBalanceDataCreditPrice {
        letter-spacing: 0px;
        color: #0F0F0F;
        font-size: 14px;

        @include mobile {
            font-size: 12px;
        }
    }

    &-YourCreditBalanceDataPricelabel {
        letter-spacing: 0px;
        color: #707070;
        font-size: 14px;

        @include mobile {
            font-size: 12px;
        }
    }

    &-HeaderHeading {
        margin: 20px 0 16px 0;

        @include mobile {
            margin: 20px 0 12px 0;
        }

        h2 {
            padding: 0 0 16px 0;
            margin: 0;
            letter-spacing: 0px;
            color: #0F0F0F;
            font-size: 16px;
            border-bottom: 1px solid #CBCBCB;

            @include mobile {
                padding: 0 0 12px 0;
                font-size: 14px;
            }
        }

        &.CartItemHeading {
            @include mobile {
                margin: 0;
            }

            h2 {
                display: flex;
                align-items: center;
                font-size: 24px;

                @include mobile {
                    font-size: 16px;
                    border-bottom: unset;
                }
            }

            p {
                margin-block-end: 0;
                margin-inline-start: 16px;
                letter-spacing: 0px;
                color: #9D9D9D;
                font-size: 16px;
                font-weight: bold;

                @include mobile {
                    font-size: 12px;
                }
            }
        }
    }

    &-HeadingFreebies {
        display: none;

        @include mobile {
            display: block;
            padding-block-end: 16px;
            margin-block-end: 16px;
            border-bottom: 1px solid #F1F1F1;
            letter-spacing: 0px;
            color: #0F0F0F;
            font-size: 12px;
            font-weight: bold;

            span {
                color: var(--primary-base-color);
            }
        }
    }

    &-DiscountBlockHeading {
        padding: 20px;
        background: #FFFFFF;
        box-shadow: 1px 1px 13px #00000005;

        @include mobile {
            padding: 16px;
        }

        p {
            letter-spacing: 0px;
            color: #0F0F0F;
            font-size: 14px;
            font-weight: bold;

            @include mobile {
                font-size: 12px;
                margin-block-end: 12px;
            }
        }
    }

    .CartCoupon { 
        .Form {
            margin-block-start: 0;
            width: 100%;

            @include mobile {
                display: flex;
                align-items: center;
            }
        }

        &-Input {
            margin-inline-end: 0;
            width: 100%;
        }

        &-Button {
            background: #F63300;
            color: #FFFFFF;
            letter-spacing: 0px;
            font-size: 14px;
            text-transform: unset;
            height: 40px;
            border-radius: 0 4px 4px 0;
            border: 1px solid #F63300;
            min-width: 138px;
            padding: 12px 12px;
            font-weight: bold;

            @include mobile {
                margin: 0;
                height: 36px;
                width: 118px;
                min-width: unset;
            }

            p {
                color: #FFFFFF;
                min-width: 98px;
                margin-block-end: 0;
                margin-block-start: -2px;
            }

            &:hover {
                background: #F63300;
                color: #FFFFFF;
                letter-spacing: 0px;
                font-size: 14px;
                text-transform: unset;
                height: 40px;
                border-radius: 0 4px 4px 0; 
                border: 1px solid #F63300;
                min-width: 138px;
                padding: 12px 20px;
                font-weight: bold;

                @include mobile {
                    margin: 0;
                    height: 36px;
                    width: 118px;
                    min-width: unset;
                }
            }
        }

        &-Button[disabled] {
            pointer-events: none;
            opacity: 1;
            border-radius: 0 4px 4px 0;

            @include mobile {
                margin: 0;
                height: 36px;
                width: 118px;
                min-width: unset;
            }
        }

        &-Input input {
            height: 40px;
            border-radius: 4px 0 0 4px;
            border: 1px solid #707070;
            border-right: none;
            width: 100%;

            @include mobile {
                height: 36px;
                font-size: 12px;
            }
        }
    }

    &-Summary {
        background: unset;
    }

    &-CheckoutButtonWrapper {
        display: flex;
        flex-direction: column;
        grid-gap: 19px;

        @include mobile {
            flex-direction: row-reverse;
        }
    }

    &-CheckoutButton {
        margin: 0;
        text-transform: unset;
        letter-spacing: 0px;
        color: #FFFFFF;
        font-size: 14px;
        height: 40px;
        border-radius: 4px;

        @include mobile {
            font-size: 12px;
            padding: 10px 17px;
        }

        &.Button:not([disabled]):hover {
            height: 40px;
            border-radius: 4px;

            @include mobile {
                font-size: 12px;
            }
        }

        &:hover {
            height: 40px;
            border-radius: 4px;

            @include mobile {
                height: 36px;
                font-size: 12px;
            }
        }

        .LockIcon {
            display: none;
        }
    }

    &-QuoteButton.Button {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #BA8377;
        border-radius: 4px;
        height: 40px;
        color: #BA8377;
        text-transform: unset;

        @include mobile {
            height: 36px;
            font-size: 12px;
            background: #BB8376;
            color: #FFFFFF;
        }

        &:hover {
            background: #FFFFFF 0% 0% no-repeat padding-box;
            border: 1px solid #BA8377;
            border-radius: 4px;
            height: 40px;
            color: #BA8377; 

            @include mobile {
                height: 36px;
                font-size: 12px;
            }
        }
    }

    &-SecurePayment {
        display: flex;
        padding: 16px 0 0 0;
        grid-gap: 10px;
    }

    &-SecurePayment-Label {
        letter-spacing: 0px;
        color: #707070;
        font-size: 14px;
        line-height: 20px;

        @include mobile {
            font-size: 12px;
        }
    }

    &-Floating {
        margin-block-start: 0px;

        @include mobile {
            margin-block-start: 24px;
        }
    }

    &-Promo {
        display: none;
    }

    &-OutOfStock {
        display: none;

        &_isActive {
            display: block;
            width: 100vw;
            height: 100vh;
            position: absolute;
        }
    }

    @include mobile {
        .CheckoutOrderSummary-ButtonWrapper {
            transform: none;
        }

        .CartItem {
            &-Wrapper_isMobileLayout {
                grid-template-columns: 100px auto;
                padding: 10px;
                border-bottom: unset;
            }

            &-Picture_isMobileLayout {
                width: 100px;
                height: 100px;
            }

            &-Title .Seller {
                font-size: 10px;
                font-weight: bold;
                padding-block-end: 7px;
            }

            &-Heading {
                margin: 0 0 7px 0;
                font-weight: 600;
            }

            &-ProductPriceData {
                display: flex;
                grid-gap: 16px;
                align-items: center;
                margin-block-end: 4px;
            }

            &-Price_isMobileLayout {
                text-align: end;
                display: flex;
                flex-direction: row;
                grid-gap: 10px;
                align-items: center;
                font-size: 14px;
            }

            .ProductPrice-DelPrice del {
                margin-block-start: 0;
            }

            &-Title {
                margin-block-end: 0;
            }

            &-Discount-Percentage {
                color: var(--primary-base-color);
                font-weight: bold;
            }

            &-DiscountTax-Label {
                letter-spacing: 0px;
                color: #707070;
                font-size: 9px;
            }

            &-ProductActions_isMobileLayout {
                height: 36px;
                // justify-content: flex-end;
                align-items: flex-end;
            }

            &-Delete_isMobileLayout {
                margin-inline-start: 0;
            }

            &-ProductActions .Remove {
                font-size: 12px;
            }

            &-Options {
                margin-block-end: 7px;
            }
        }
    }

    &-ActionButtons {
        &:last-of-type {
            display: none;
        }

        @include mobile {
            display: block;
            width: 100vw;
            position: fixed;
            inset-inline-start: 0;
            inset-block-end: 0;
            background: #FFFFFF;
            padding: 18px 24px;
            box-shadow: 0px -3px 12px #00000017;
            border-radius: 4px 4px 0px 0px;
            z-index: 100;
        }
    }

    .ProductLinks {
        background: #FFFFFF;

        &-List {
            @include desktop {
                grid-template-columns: repeat(5, 1fr);
            }
        }

        &-Title {
            padding: 13px 0 24px 0;
            margin: 0;
            font-family: $font-Montserrat;
        }
    }

    .CartAdditionalFees {
        &-Option {
            text-transform: unset;
            border-bottom: unset;

            @include desktop {
                font-size: 16px;
                margin-block-end: 24px;
            }
    
            @include mobile {
                margin-block-end: 16px;
            }

            span {
                &:first-of-type {
                    color: #707070;
                }
            }

            &-Label {
                letter-spacing: 0px;
                color: #707070;
                font-size: 16px;
            }
        }
    }

    .CheckoutOrderSummary {
        &-DeliveryExtraFee {
            display: flex;
            flex-direction: column;
        }
    }

    .Fee {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include desktop {
            margin-block-end: 24px;
        }

        @include mobile {
            margin-block-end: 16px;
        }

        &-Name {
            margin-block-start: 0;
            text-transform: capitalize;

            @include mobile {
                font-size: 14px;
            }
        }

        .Field.Field_type_select {
            margin-block-start: 0;
        }

        .FieldSelect-Select {
            border-radius: 4px;
            font-size: 14px;
            border: 1px solid #707070;
            height: 40px;
            padding-block: 0;

            @include mobile {
                height: 36px;
            }
        }
    }

    .CartCoupon-Button.RemoveCoupon.Button {
        border-radius: 4px;
        padding: 12px 5px;
    }
}

body.cart-page .Breadcrumbs {
    background: #FAFAFA;
}

body.cart-page .Breadcrumbs-List {
    @include mobile {
        padding: 10px 0 4px 0;
        border-bottom: 1px solid #F1F1F1;
    }
}
